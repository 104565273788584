.linkNavbar {
    text-decoration: none;
    color: white;
    opacity: 0.6;
    transition: opacity 0.15s ease-in-out;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.linkNavbar:hover {
    opacity: 1 !important;
}

.activeLink {
    text-decoration: none;
    color: white;
    opacity: 1 !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.linkNavbarBlack {
    text-decoration: none;
    color: rgb(0, 0, 0);
    opacity: 0.5;
    transition: opacity 0.15s ease-in-out;
}

.activeLinkBlack {
    text-decoration: none;
    color: rgb(0, 0, 0);
    opacity: 1 !important;
}

.logoNavbar {
    width: 35px;
    margin-top: 8px;
    transition: filter 0.3s ease;
}
.logoNavbar:hover {
    filter:  grayscale(60%);
}
