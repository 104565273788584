/* Images */ 

.boxImage1 {
    background-image: url("../Media/inicio.webp");
    background-repeat: no-repeat;
    background-size: cover;
    position: sticky;
}

.boxImage2 {
    background-image: url("../Media/trackthetrucker.webp");
    background-repeat: no-repeat;
    background-size: cover;
    position: sticky;
}

.boxImage3 {
    background-image: url("../Media/quienessomos.webp");
    background-repeat: no-repeat;
    background-size: cover;
    position: sticky;
}

.boxImage4 {
    background-image: url("../Media/contacto.webp");
    background-repeat: no-repeat;
    background-size: cover;
    position: sticky;
}

.boxImage5 {
    background-image: url("../Media/notfound.webp");
    background-repeat: no-repeat;
    background-size: cover;
    position: sticky;
}

/* Roots */ 

.MuiCard-root {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05) !important;
    border-radius: 8px !important;
}

.MuiAccordion-root {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05) !important;
    border-radius: 8px !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root::before {
    background-color: #f5f7fb !important;
}

.css-awnenc-MuiPaper-root-MuiAccordion-root::before {
    background-color: #f5f7fb !important;
}

/* Animated Gradient */

.animatedVariant {
    --bg-size: 400%;
    --color-one: #0ab7fc;
    --color-two: #254cfb;
    font-size: clamp(3rem, 25vmin, 8rem);
    background: linear-gradient( 90deg, var(--color-one), var(--color-two), var(--color-one) ) 0 0 / var(--bg-size) 100%;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    animation: move-bg 8s infinite linear;
}

@keyframes move-bg {
    to {
        background-position: var(--bg-size) 0;
    }
}

/* Titles */ 

.titleVariant {
    font-size: 9rem !important;
    line-height: 8rem !important;
}

.titleVariant2 {
    font-size: 5rem !important;
    line-height: 4.5rem !important;
}

@media (max-width: 600px) {
    .titleVariant {
        font-size: 6rem !important;
        line-height: 5rem !important;
    }
    .titleVariant2 {
        font-size: 3rem !important;
        line-height: 2.8rem !important;
    }
}

/* Icons and Images */ 

.iconSmall {
    color: black !important;
    opacity: 0.5 !important;
    transition: opacity 0.3s ease-in-out !important;
}

.iconSmall:hover {
    opacity: 1 !important;
}

.logoInstagram {
    width: 35px;
    margin-right: 5px;
}

.iconSocial {
    width: 17px;
    height: 17px;
    margin-top: 1px;
}
